export default function Circle({ value, label, colors }) {
  return (
    <div style={{ color: colors }} className="inline-block mx-3 text-center">
      <div
        style={{ borderColor: colors }}
        className="w-28 h-28 border-4 text-2xl  rounded-full flex justify-center items-center font-bold mb-1 mt-2"
      >
        {value}
      </div>
      <span
        className="text-xs font-bold uppercase "
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </div>
  );
}
