import React, { useEffect, useState } from "react";

const BetterInput2 = (props) => {
    const [v, setV] = useState("");
    const [isSync, setIsSync] = useState(false);

    const visibleValue = props.visibleValue;
    const setVisibleValue = props.setVisibleValue;
    const setValue = props.setValue;

    useEffect(() => {
        if (!setValue) return;
        if (isSync) {
            setIsSync(false);
            return;
        }
        const timeout = setTimeout(() => {
            setValue(v);
        }, 700);
        return () => clearTimeout(timeout);
    }, [v, setValue, isSync]);

    /* useEffect(() => {
        setVisibleValue(v);
    }, [v, setVisibleValue]); */

    useEffect(() => {
        setV(visibleValue);
        setIsSync(true);
    }, [visibleValue]);

    const label = () => {
        if (props.label === undefined) {
            return "";
        }
        if (props.label.includes("%s")) {
            return props.label.replace("%s", props.visibleValue);
        }
        return props.label + (props.label.endsWith("\0") ? "" : props.visibleValue);
    };

    const p = {
        className: props.className,
        type: props.type,
        placeholder: props.placeholder,
        id: props.id,
    };

    return (
        <div>
            {props.label !== undefined && (
                <label className={"block " + props.labelClass} dangerouslySetInnerHTML={{ __html: label() }} />
            )}
            <input
                {...p}
                value={v}
                onChange={async (e) => {
                    let value = e.target.value.replaceAll(",", ".");
                    if (isNaN(value)) {
                        value = "";
                    } else {
                        value = "" + value;
                    }

                    setV(value);
                    setVisibleValue(value);
                    //props.setVisibleValue(value);
                }}
            />
            {/* {props.type === "number" ? (
                <input {...props} value={props.visibleValue} onChange={(e) => props.setVisibleValue(e.target.value)} />
            ) : (
                <input
                    {...props}
                    value={props.visibleValue}
                    onChange={(e) => props.setVisibleValue(e.target.value)}
                    onMouseUp={(e) => {
                        props.onChange(e.target.value);
                    }}
                    onTouchEnd={(e) => {
                        props.onChange(e.target.value);
                    }}
                />
            )} */}
        </div>
    );
};

export default BetterInput2;
