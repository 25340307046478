export default function Logo({ classes }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37 37"
      className={classes}
    >
      <g id="Group_221" transform="translate(-995 -6591)">
        <circle
          id="Ellipse_19"
          cx="18.5"
          cy="18.5"
          r="18.5"
          transform="translate(995 6591)"
          fill="#ffd938"
        />
        <path
          id="Path_14"
          d="M.111.272l0,11.209L2.416.8ZM.744,17.007l6.245-1.716L6.983,1.771,4.25,1.2Zm8.547-1.632,5.755,1.487L15.037.243,12.715.8ZM8.872,1.751l.019,8.188,1.855-8.661ZM4.669-.763,7.922.074l2.353-.7L5.005-2.2ZM-1.777-2.449,2.86-1.259l.7-3.141,9.482,3.012L17.03-2.539V19.807L7.95,17.117l-9.736,2.8Z"
          transform="translate(1005.532 6601.231)"
          fill="#ff2d16"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}
