import React, { useState, useEffect } from "react";

const BetterInput = (props) => {
    const [visibleValue, setVisibleValue] = useState(props.value);

    useEffect(() => {
        if (props.type !== "number") return;
        const timeout = setTimeout(() => {
            props.onChange(visibleValue.replaceAll(",", "."));
        }, 700);
        return () => clearTimeout(timeout);
    }, [visibleValue, props]);

    const label = () => {
        if (props.label === undefined) {
            return "";
        }
        if (props.label.includes("%s")) {
            return props.label.replace("%s", visibleValue);
        }
        return props.label + (props.label.endsWith("\0") ? "" : visibleValue);
    };

    return (
        <div>
            {props.label !== undefined && <label className="block" dangerouslySetInnerHTML={{ __html: label() }} />}
            {props.type === "number" ? (
                <input
                    {...props}
                    value={visibleValue}
                    onChange={(e) => {
                        setVisibleValue(e.target.value);
                        console.log("e.target.value: " + e.target.value);
                    }}
                />
            ) : (
                <input
                    {...props}
                    value={visibleValue}
                    onChange={(e) => setVisibleValue(e.target.value)}
                    onMouseUp={(e) => {
                        props.onChange(e.target.value);
                    }}
                    onTouchEnd={(e) => {
                        props.onChange(e.target.value);
                    }}
                />
            )}
        </div>
    );
};

export default BetterInput;
