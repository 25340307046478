import "./App.css";
import React, { useEffect, useState } from "react";
import Klimatkalkylator from "./Klimatkalkylator";
import BiddingZone from "./BiddingZone";
import localrates from "./localdata.json";
import RegionSelect from "./components/RegionSelect";
import Logo from "./components/logo";
import Translate from "./translate/Translate.json";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import BetterInput from "./components/BetterInput";
import useQueryParam from "./components/useQueryParam";

function App() {
    const [domain, setDomain] = useState("");
    const [windows, setWindows] = useState(Translate.defaults.windows);
    const [buildYear, setBuildYear] = useState(Translate.defaults.buildYear);
    const [defaultU, setDefaultU] = useState(1.5);
    const [ready, setReady] = useState(false);
    const [warning, setWarning] = useState(false);
    const [biddingZone, setBiddingZone] = useState(null);
    const [exchangeRates, setExchangeRates] = useState(null);
    const [lang, setLang] = useState("en");
    const [query, setQuery] = useQueryParam("lang", "");

    const checkIfReady = () => {
        if (domain !== "") {
            setReady(!ready);
            setWarning(false);
        } else {
            setWarning(true);
        }
    };

    async function fetchExchangeRates() {
        "https://data.norges-bank.no/api/data/EXR/B.DKK+SEK+EUR+PLN+GBP.NOK.SP?format=sdmx-json&lastNObservations=1&locale=en";
        /*const response = await fetch(url);
    if (response.status !== 200) {
        throw new Error("Failed to fetch rates!");
    }
    const json = await response.json();
    if (json.errors) {
        throw new Error("Json error!");
    }*/
        const json = localrates; // så vi inte spammar skiten ur norskarna under dev..
        const result = [];
        let indexes = json.data.structure.dimensions.series[1].values;
        let values = Object.values(json.data.dataSets[0].series);
        for (let i = 0; i < indexes.length; i++) {
            if (values[i].attributes[0] === 1) result[indexes[i].id] = parseFloat(values[i].observations[0][0]);
            else result[indexes[i].id] = values[i].observations[0][0] / 100;
        }
        return result;
    }

    useEffect(() => {
        (async function awaitData() {
            const rates = await fetchExchangeRates();
            setExchangeRates(rates);
        })();
    }, []);

    useEffect(() => {
        var _mtm = (window._mtm = window._mtm || []);
        _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
        (function () {
            var d = document,
                g = d.createElement("script"),
                s = d.getElementsByTagName("script")[0];
            g.async = true;
            g.src = "https://stats.nordan.com/js/container_mkJ0Vv3f.js";
            s.parentNode.insertBefore(g, s);
        })();
    }, []);

    useEffect(() => {
        if (query === "no") {
            setLang("no");
        }
    }, [query]);

    const defaultUValues = (year) => {
        return Translate.defaults.uValues[year];
    };

    return (
        <>
            {warning && (
                <div
                    onClick={() => setWarning(false)}
                    className="cursor-pointer bg-dawnblue bg-opacity-80 text-nordan shadow-2xl w-96 absolute right-10  bottom-10 p-6 z-50 rounded-2xl"
                >
                    <strong className="uppercase flex items-center">
                        <InformationCircleIcon className="w-7 h-7 inline-block mr-1" /> {Translate[lang].warningTitle}
                    </strong>
                    {Translate[lang].warningBody}
                </div>
            )}
            <main>
                {/* {console.log("app re-render")} */}
                <div className=" absolute right-6 top-6 z-50 flex justify-center items-center print:hidden">
                    <div className="select after:!text-nordan after:!top-3 after:!right-5">
                        <select
                            id="region"
                            autoComplete="off"
                            className="h-12 bg-transparent w-32 text-right mb-0 mr-2 uppercase font-bold text-sm text-nordan"
                            onChange={(e) => {
                                setLang(e.target.value);
                                setQuery(e.target.value);
                            }}
                            value={lang}
                        >
                            <option value="en">English</option>
                            <option value="no">Norsk</option>
                            {/* <option value="se">Svenska</option>
                            <option value="pl">Polish</option> */}
                        </select>
                    </div>
                    <div className="w-10 h-10 cursor-pointer " onClick={() => setReady(false)}>
                        <Logo />
                    </div>
                </div>
                <div className={`p-6 App ${ready ? `hidden` : `flex`}`}>
                    <section className="md:mt-10 bg-nightblue p-6 md:p-10 xl:p-14 rounded-2xl max-w-screen-md mx-auto shadow-2xl relative">
                        <div className=" mb-16">
                            {/* <input type="hidden" name="task" value="pdf" /> */}

                            <div className="">
                                <h3>{Translate[lang].getStarted}</h3>
                                <div className={`w-full  rounded-lg `}>
                                    <div className={`${warning && " text-nordan"}`}>
                                        {domain ? Translate[lang].region + domain : Translate[lang].pleaseChooseRegion}
                                    </div>

                                    <RegionSelect
                                        setDomain={setDomain}
                                        setBiddingZone={setBiddingZone}
                                        BiddingZone={BiddingZone}
                                    />
                                    {/*<input
                                        id="address"
                                        className=""
                                        type="text"
                                        placeholder="Your address"
                                        onChange={(e) => {
                                        setDomain(e.target.value);
                                        setBiddingZone(BiddingZone.byIndex(e.target.value));
                                        }}
                                        value={domain}
                                    />*/}
                                </div>
                                <div className="">
                                    {/* <div className="w-full mt-5">
                                        <input
                                            id="windows"
                                            className={`h-12 rounded-lg  ${
                                                warning
                                                    ? "border border-nordan text-nordan placeholder-nordan"
                                                    : "placeholder-white"
                                            }`}
                                            type="number"
                                            placeholder={Translate[lang].amountWindows}
                                            onChange={(e) => setWindows(e.target.value)}
                                            value={windows}
                                        />
                                    </div> */}
                                    <div className="setItem mt-16">
                                        <BetterInput
                                            id="amountWindows"
                                            type="range"
                                            step={1}
                                            min="0"
                                            max={Translate.defaults.maxWindows}
                                            onChange={(e) => setWindows(e)}
                                            value={windows}
                                            className="range"
                                            label={Translate[lang].amountWindows}
                                        />
                                    </div>
                                    <div className="setItem">
                                        <BetterInput
                                            id="buildYear"
                                            type="range"
                                            step={10}
                                            min={Translate.defaults.startYear}
                                            max={Translate.defaults.endYear}
                                            onChange={(e) => {
                                                setBuildYear(e);
                                                setDefaultU(defaultUValues(e));
                                            }}
                                            value={buildYear}
                                            className="range"
                                            label={Translate[lang].buildYear}
                                        />
                                    </div>
                                    {/* <div className="w-full select">
                                        <select
                                            id="region"
                                            className={`h-12 rounded-lg ${
                                                warning && "border border-nordan text-nordan"
                                            }`}
                                            onChange={(e) => setBuildYear(e.target.value)}
                                            value={buildYear}
                                        >
                                            <option key="" value="" disabled>
                                                {Translate[lang].windowsFrom}
                                            </option>
                                            <option value="1940">1940</option>
                                            <option value="1950">1950</option>
                                            <option value="1960">1960</option>
                                            <option value="1970">1970</option>
                                            <option value="1980">1980</option>
                                            <option value="1990">1990</option>
                                            <option value="2000">2000</option>
                                            <option value="2010">2010</option>
                                        </select>
                                    </div> */}
                                </div>
                                <button
                                    type="button"
                                    className="rounded-br-2xl rounded-bl-2xl bg-nordan text-nightblue p-4 font-bold uppercase absolute right-0 left-0 bottom-0 w-full"
                                    onClick={() => checkIfReady()}
                                    name="button"
                                >
                                    {Translate[lang].calculate}
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
                <div className={`Calc ${ready ? `absolue top-0 bottom-0` : `hidden`}`}>
                    {ready && exchangeRates && (
                        <Klimatkalkylator
                            setWindows={setWindows}
                            setBiddingZone={setBiddingZone}
                            setBuildYear={setBuildYear}
                            options={{
                                biddingZone,
                                windows,
                                buildYear,
                                exchangeRates,
                                defaultU,
                            }}
                            lang={lang}
                        />
                    )}
                </div>
            </main>
        </>
    );
}

export default App;
