import React, { useEffect, useState, useCallback, useRef } from "react";
import Chart from "./components/Chart";
import WeekChart from "./components/WeekChart";
import YearKwh from "./components/YearKwh";
import Donut from "./components/Donut";
import BiddingZone from "./BiddingZone";
import Footer from "./components/Footer";
import Circle from "./components/Circle";
import DropDown from "./components/Dropdown";
import Translate from "./translate/Translate.json";
import Table2 from "./components/Table2";
import { AdjustmentsHorizontalIcon, PhotoIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import Timer from "./components/Timer";
import BetterInput from "./components/BetterInput";
import BetterInput2 from "./components/BetterInput2";
import screenshot from "./components/Screenshot";

const API_TOKEN = "jd46RgiV-t_nTaUtUJmUFMO5qtUBMEoG";
const API_URL = "https://phplaravel-461579-3158437.cloudwaysapps.com/api?";
//const API_URL = "http://localhost:8000/api?";

async function fetchData(domain) {
    let url = API_URL + "domain=" + domain;
    const response = await fetch(url, {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${API_TOKEN}`,
        },
    });
    if (response.status !== 200) {
        throw new Error("Failed to fetch prices!");
    }
    const json = await response.json();
    if (json.errors) {
        throw new Error("Json error!");
    }
    return json;
}

export default function Klimatkalkylator({ options, setBiddingZone, lang }) {
    const [priceData, setPriceData] = useState(null); // 2d array, 0-7, 6 = idag, 7 = imorgon, tidigare element är förra veckan, därefter 0-24 med värde för varje timme
    const [rawData, setRawData] = useState(null); // omanipulerat data call från vår backend, .data = priceData, .temp = tempWeek, .tempToday, .tempTomorrow
    const [tempWeek, setTempWeek] = useState(null); // array 0-7, data för varje dag
    const [tempToday, setTempToday] = useState(null); // array 0-24, data för varje timme
    const [tempTomorrow, setTempTomorrow] = useState(null); //  ^
    const [tempDayahead, setTempDayahead] = useState(null);
    const [weeklyData, setWeeklyData] = useState(null); // genomsnitt för index 0-6 av priceData
    const [oldU, setOldU] = useState(options.defaultU); // används för cost och kwh uträkning
    const [oldUWeek, setOldUWeek] = useState(null);
    const [newUWeek, setNewUWeek] = useState(null);
    const [newU, setNewU] = useState(0.9); // används för cost och kwh uträkning
    const [indoorTemp, setIndoorTemp] = useState(20); // används för cost och kwh uträkning
    const [outTempOffset, setOutTempOffset] = useState(0); // ändrar tempToday och tempTomorrow
    const [newCostHourly, setNewCostHourly] = useState(null); // 2d array, 0 = idag, 1 = imorgon, därefter 0-24 med värde för varje timme
    const [oldCostHourly, setOldCostHourly] = useState(null); //  ^
    const [newKwhHourly, setNewKwhHourly] = useState(null); //    ^ // FAKTISKT W/H NU!!!
    const [oldKwhHourly, setOldKwhHourly] = useState(null); //    ^
    const [pct, setPct] = useState(0); // procent skillnad mellan new och old U
    const [showElstod, setShowElstod] = useState(false); // vissa har det bra!
    const [elstod, setElstod] = useState(0);
    const [fixedPrice, setFixedPrice] = useState("");
    const [visibleFixedPrice, setVisibleFixedPrice] = useState("" + options.biddingZone.avgPrice);
    const [fixedWindowsArea, setFixedWindowsArea] = useState(-1);
    const [fixedTemp, setFixedTemp] = useState(""); // alla värden kommer tbx som string från inputen ändå
    const [hideMenu, setHideMenu] = useState(false);
    const [showTomorrow, setShowTomorrow] = useState(false);
    const [timeUntilUpdate, setTimeUntilUpdate] = useState(0);
    const [showClimate, setShowClimate] = useState(false);
    const [oldC02Hourly, setOldC02Hourly] = useState(null);
    const [newC02Hourly, setNewC02Hourly] = useState(null);
    const [oldC02Week, setOldC02Week] = useState(null);
    const [newC02Week, setNewC02Week] = useState(null);
    const [showInfo, setShowInfo] = useState(false);
    const [monetaryEnabled, setMonetaryEnabled] = useState(true);
    const [climateEnabled, setClimateEnabled] = useState(true);
    const ref = useRef();

    const avg = (data) => {
        let result = 0.0;
        data.forEach((element) => {
            result += parseFloat(element);
        });
        result /= data.length;
        return parseFloat(result).toPrecision(5); // så vi inte har typ 12 st decimaler
    };

    const calcWeeklyData = useCallback((data) => {
        const result = [];
        for (let i = 0; i < data.length; i++) {
            result[i] = parseFloat(avg(data[i])).toPrecision(3);
        }
        return result;
    }, []);

    const prettierDate = useCallback(
        (dayOffset = 0) => {
            const months = Translate[lang].months ? Translate[lang].months : Translate["en"].months;
            let date = new Date();
            date.setDate(date.getDate() + dayOffset);
            return date.getDate() + " " + months[date.getMonth()];
        },
        [lang]
    );

    useEffect(() => {
        // hämtar data från backend
        const convertToCurrency = (data, rates, currency) => {
            if (rates === null) {
                console.log("rates is null!");
                return null;
            }
            if (data === null) {
                console.log("data is null!");
                return null;
            }
            const local = rates[currency];
            const eur = rates["EUR"];
            const result = [];
            for (let i = 0; i < data.length; i++) {
                result[i] = [];
                for (let j = 0; j < data[i].length; j++) {
                    let tmp = data[i][j];
                    tmp *= eur; // till nok
                    if (currency !== "NOK") {
                        tmp /= local; // till angiven valuta
                    }
                    tmp /= 1000; // från pris per mwh till kwh
                    result[i][j] = parseFloat(tmp).toPrecision(3);
                }
            }
            return result;
        };
        const calcWeeklyData = (data) => {
            const result = [];
            for (let i = 0; i < data.length; i++) {
                result[i] = parseFloat(avg(data[i])).toPrecision(3);
            }
            return result;
        };
        (async function awaitData() {
            // andreas knows the way // (async () => { })();
            const json = await fetchData(options.biddingZone.index);
            const data = json.data;
            const converted = convertToCurrency(data, options.exchangeRates, options.biddingZone.currency);
            if (!json.data || json.data.length !== 8) {
                setMonetaryEnabled(false);
                setShowClimate(true);
            }
            if (!json.temp || json.temp.length !== 8) {
                setClimateEnabled(false);
            }
            //setShowElstod(options.biddingZone.currency === "NOK");
            setShowElstod(false);
            setElstod(0);
            setFixedPrice("");
            setPriceData(converted);
            setRawData(json);
            setTempWeek(json.temp);
            setTempToday(json.tempToday);
            setTempTomorrow(json.tempTomorrow);
            setTempDayahead(json.tempDayahead); // ändra den här till tempDayahea
            setWeeklyData(calcWeeklyData(converted));
            setShowTomorrow(new Date().getDate() === parseFloat(json.createdAt));
            setTimeUntilUpdate(json.diff);
        })();
    }, [options]);

    useEffect(() => {
        if (rawData !== null) {
            if (fixedTemp !== "") {
                const _fixedTemp = Number(fixedTemp);
                setTempWeek(Array(8).fill(_fixedTemp));
                const fixed = Array(24).fill(_fixedTemp);
                setTempToday(fixed);
                setTempTomorrow(fixed);
                setTempDayahead(fixed);
            } else {
                setTempWeek(rawData.temp);
                setTempToday(rawData.tempToday);
                setTempTomorrow(rawData.tempTomorrow);
                setTempDayahead(rawData.tempDayahead);
            }
        }
    }, [rawData, fixedTemp]);

    useEffect(() => {
        const convertToCurrency = (data, rates, currency) => {
            if (rates === null) {
                console.log("rates is null!");
                return null;
            }
            if (data === null) {
                console.log("data is null!");
                return null;
            }
            const local = rates[currency];
            const eur = rates["EUR"];
            const result = [];
            for (let i = 0; i < data.length; i++) {
                result[i] = [];
                for (let j = 0; j < data[i].length; j++) {
                    let tmp = data[i][j];
                    tmp *= eur; // till nok
                    if (currency !== "NOK") {
                        tmp /= local; // till angiven valuta
                    }
                    tmp /= 1000; // från pris per mwh till kwh
                    result[i][j] = parseFloat(tmp).toPrecision(3);
                }
            }
            return result;
        };
        if (showElstod && rawData !== null) {
            let converted = [];
            if (fixedPrice > 0) {
                for (let i = 0; i < rawData.data.length; i++) {
                    converted[i] = [];
                    for (let j = 0; j < rawData.data[i].length; j++) {
                        converted[i][j] = fixedPrice;
                    }
                }
            } else {
                converted = convertToCurrency(rawData.data, options.exchangeRates, options.biddingZone.currency); // orörd
            }

            if (elstod === 0) {
                setPriceData(converted);
            } else {
                let result = [];
                let decElstod = (100 - elstod) / 100;
                for (let i = 0; i < converted.length; i++) {
                    result[i] = [];
                    for (let j = 0; j < converted[i].length; j++) {
                        let tmp = converted[i][j];
                        if (tmp > 0.7) {
                            tmp = tmp - 0.7;
                            tmp *= decElstod;
                            tmp = tmp + 0.7;
                        }
                        result[i][j] = tmp;
                    }
                }
                setPriceData(result);
            }
        }
    }, [showElstod, elstod, rawData, options, fixedPrice]);

    useEffect(() => {
        const convertToCurrency = (data, rates, currency) => {
            if (rates === null) {
                console.log("rates is null!");
                return null;
            }
            if (data === null) {
                console.log("data is null!");
                return null;
            }
            const local = rates[currency];
            const eur = rates["EUR"];
            const result = [];
            for (let i = 0; i < data.length; i++) {
                result[i] = [];
                for (let j = 0; j < data[i].length; j++) {
                    let tmp = data[i][j];
                    tmp *= eur; // till nok
                    if (currency !== "NOK") {
                        tmp /= local; // till angiven valuta
                    }
                    tmp /= 1000; // från pris per mwh till kwh
                    result[i][j] = parseFloat(tmp).toPrecision(3);
                }
            }
            return result;
        };
        if (!showElstod && rawData !== null) {
            let data = [];
            if (fixedPrice > 0) {
                for (let i = 0; i < 8; i++) {
                    data[i] = [];
                    for (let j = 0; j < 24; j++) {
                        data[i][j] = fixedPrice;
                    }
                }
            } else {
                data = convertToCurrency(rawData.data, options.exchangeRates, options.biddingZone.currency); // orörd
            }
            setPriceData(data);
        }
    }, [fixedPrice, showElstod, options, rawData]);

    useEffect(() => {
        // ändrar temperaturerna som används för uträkning
        function roundedToFixed(input, digits) {
            var rounder = Math.pow(10, digits);
            return Number((Math.round(input * rounder) / rounder).toFixed(digits));
        }
        const offsetTemp = (temp, offset) => {
            const offs = parseInt(offset);
            const result = [];
            temp.forEach((e) => {
                result.push(roundedToFixed(e + offs, 1));
            });
            return result;
        };
        if (rawData != null) {
            setTempWeek(offsetTemp(rawData.temp, outTempOffset));
            setTempToday(offsetTemp(rawData.tempToday, outTempOffset));
            setTempTomorrow(offsetTemp(rawData.tempTomorrow, outTempOffset));
        }
    }, [rawData, outTempOffset]);

    useEffect(() => {
        // gör kostnads och kwh uträkningar
        //funkar, 120.375 -> 120.38 etc
        function roundedToFixed(input, digits) {
            var rounder = Math.pow(10, digits);
            return (Math.round(input * rounder) / rounder).toFixed(digits);
        }
        const costForHour = (uArea, priceData, temp) => {
            /* et value = uArea;
            let tempDelta = indoorTemp - temp; //
            if (tempDelta < 0) tempDelta = 0;
            value *= tempDelta;
            value /= 1000;
            let price = priceData * 100; // gör om från kronor till ören
            value *= price;
            return value / 10; */
            const wh = kwhForHour(uArea, temp);
            const kwh = wh / 1000;
            const cost = kwh * priceData;
            return cost;
        };
        const costForDay = (uArea, prices, temps) => {
            // alla "ForDay" funktioner loopar igenom array som innehåller 24 element, på så sätt blir
            let result = []; // det 24 uträkningar för en dag, och en uträkning för varje timme!
            for (let i = 0; i < prices?.length; i++) {
                let temp = temps;
                if (temps.length !== undefined) {
                    temp = temps[i];
                }
                result.push(roundedToFixed(costForHour(uArea, prices[i], temp), 2));
            }
            return result;
        };
        const kwhForHour = (uArea, temp) => {
            // överallt där det står kwh så hanterar man egentligen w nu
            let value = uArea;
            let tempDelta = indoorTemp - temp;
            if (tempDelta < 0) tempDelta = 0;
            value *= tempDelta;
            //value /= 1000; // <---------------- numera w/h !
            return value;
        };
        const kwhForDay = (uArea, temps) => {
            let result = [];

            temps.forEach((temp) => {
                result.push(roundedToFixed(kwhForHour(uArea, temp), 2));
            });
            return result;
        };
        const c02ForHour = (uArea, temp) => {
            let kwh = kwhForHour(uArea, temp);
            kwh /= 1000;
            return kwh * options.biddingZone.c02 * 1000; // back to grams!
        };
        const c02ForDay = (uArea, temps) => {
            let result = [];
            if (temps.length === undefined) {
                for (let i = 0; i < 24; i++) {
                    result.push(roundedToFixed(c02ForHour(uArea, temps), 2));
                }
                return result;
            }
            temps.forEach((temp) => {
                result.push(roundedToFixed(c02ForHour(uArea, temp), 2));
            });
            return result;
        };
        if (tempToday !== null && tempTomorrow !== null && priceData !== null && climateEnabled) {
            let windowsArea = options.windows * (1.2 * 1.2);
            if (fixedWindowsArea > 0) {
                // om fixed window area är satt så använd det värdet istället
                windowsArea = fixedWindowsArea;
            }
            const oldUArea = windowsArea * oldU; // oldU/newU har samma värde som de ser ut på frontended (1.4, 0.8 t.e.x)
            const newUArea = windowsArea * newU;

            const priceToday = priceData[priceData.length - 2];
            const priceTomorrow = priceData[priceData.length - 1];

            let newCostWeek = [];
            let oldCostWeek = [];
            let newC02Week = [];
            let oldC02Week = [];
            for (let i = 0; i < 8; i++) {
                if (i === priceData.length - 2) {
                    newCostWeek[i] = costForDay(newUArea, priceData[i], tempToday);
                    oldCostWeek[i] = costForDay(oldUArea, priceData[i], tempToday);
                    newC02Week[i] = c02ForDay(newUArea, tempToday);
                    oldC02Week[i] = c02ForDay(oldUArea, tempToday);
                } else if (i === priceData.length - 1) {
                    newCostWeek[i] = costForDay(newUArea, priceData[i], tempTomorrow);
                    oldCostWeek[i] = costForDay(oldUArea, priceData[i], tempTomorrow);
                    newC02Week[i] = c02ForDay(newUArea, tempTomorrow);
                    oldC02Week[i] = c02ForDay(oldUArea, tempTomorrow);
                } else {
                    newCostWeek[i] = costForDay(newUArea, priceData[i], tempWeek[i]);
                    oldCostWeek[i] = costForDay(oldUArea, priceData[i], tempWeek[i]);
                    newC02Week[i] = c02ForDay(newUArea, tempWeek[i]);
                    oldC02Week[i] = c02ForDay(oldUArea, tempWeek[i]);
                }
            }

            const c = newCostWeek.slice(0, 7).map((day) => day.reduce((acc, curr) => acc + Number(curr), 0)); // 23,99
            const d = oldCostWeek.slice(0, 7).map((day) => day.reduce((acc, curr) => acc + Number(curr), 0)); // 39.98

            /*             let newUWeekTmp = calcWeeklyData(newCostWeek);
            let oldUWeekTmp = calcWeeklyData(oldCostWeek);
            console.log("calcWeeklyData(newCostWeek)");
            console.log(newUWeekTmp); */
            setNewUWeek(c);
            setOldUWeek(d);

            let newC02HourlyTmp = [];
            newC02HourlyTmp[0] = newC02Week[newC02Week.length - 2];
            newC02HourlyTmp[1] = newC02Week[newC02Week.length - 1];
            newC02HourlyTmp[2] = c02ForDay(newUArea, tempDayahead);
            setNewC02Hourly(newC02HourlyTmp);

            let oldC02HourlyTmp = [];
            oldC02HourlyTmp[0] = oldC02Week[oldC02Week.length - 2];
            oldC02HourlyTmp[1] = oldC02Week[oldC02Week.length - 1];
            oldC02HourlyTmp[2] = c02ForDay(oldUArea, tempDayahead);
            setOldC02Hourly(oldC02HourlyTmp);

            const e = newC02Week.slice(0, 7).map((day) => day.reduce((acc, curr) => acc + Number(curr), 0));
            const f = oldC02Week.slice(0, 7).map((day) => day.reduce((acc, curr) => acc + Number(curr), 0));

            /*             let newC02WeekTmp = calcWeeklyData(newC02Week);
            let oldC02WeekTmp = calcWeeklyData(oldC02Week); */
            setNewC02Week(e);
            setOldC02Week(f);

            let newCostHourly = [];
            newCostHourly[0] = costForDay(newUArea, priceToday, tempToday);
            newCostHourly[1] = costForDay(newUArea, priceTomorrow, tempTomorrow);
            setNewCostHourly(newCostHourly);

            let oldCostHourly = [];
            oldCostHourly[0] = costForDay(oldUArea, priceToday, tempToday);
            oldCostHourly[1] = costForDay(oldUArea, priceTomorrow, tempTomorrow);
            setOldCostHourly(oldCostHourly);

            /* console.log(newCostHourly[0].reduce((acc, curr) => acc + Number(curr), 0));
            console.log(oldCostHourly[0].reduce((acc, curr) => acc + Number(curr), 0)); */

            let newKwhHourly = [];
            newKwhHourly[0] = kwhForDay(newUArea, tempToday);
            newKwhHourly[1] = kwhForDay(newUArea, tempTomorrow);
            newKwhHourly[2] = kwhForDay(newUArea, tempDayahead);
            setNewKwhHourly(newKwhHourly);

            let oldKwhHourly = [];
            oldKwhHourly[0] = kwhForDay(oldUArea, tempToday);
            oldKwhHourly[1] = kwhForDay(oldUArea, tempTomorrow);
            oldKwhHourly[2] = kwhForDay(oldUArea, tempDayahead);
            setOldKwhHourly(oldKwhHourly);
        }
    }, [
        tempToday,
        tempTomorrow,
        tempDayahead,
        tempWeek,
        newU,
        oldU,
        priceData,
        indoorTemp,
        fixedWindowsArea,
        climateEnabled,
        options,
        calcWeeklyData,
    ]);

    const Circles = () => {
        let title = Translate[lang].moneySaved;
        let subtext = Translate[lang].savingsIn + options.biddingZone.currency;

        let newWeekData = newUWeek;
        let oldWeekData = oldUWeek;
        let newHourlyData = newCostHourly;
        let oldHourlyData = oldCostHourly;

        if (showClimate) {
            title = Translate[lang].carbonSaved;
            subtext = Translate[lang].gramsCarbon;

            newWeekData = newC02Week;
            oldWeekData = oldC02Week;
            newHourlyData = newC02Hourly;
            oldHourlyData = oldC02Hourly;
        }

        if (
            newHourlyData === null ||
            oldHourlyData === null ||
            (!showClimate && !monetaryEnabled) ||
            (showClimate && !climateEnabled)
        ) {
            return (
                <div className="shadow-2xl py-10 rounded-3xl">
                    <h2 className="mb-0 mt-10">{title}</h2>
                    <p className="mb-10">{subtext}</p>
                    <Circle value={"-"} label={Translate[lang].thisWeek} colors={Translate["theme"].circles[0]} />
                    <Circle value={"-"} label={Translate[lang].today} colors={Translate["theme"].circles[1]} />
                    <Circle value={"-"} label={Translate[lang].tomorrow} colors={Translate["theme"].circles[2]} />
                </div>
            );
        }

        return (
            <div className="shadow-2xl py-10 rounded-3xl">
                <h2 className="mb-0 mt-10">{title}</h2>
                <p className="mb-10">{subtext}</p>
                <Circle
                    value={Sumup(newWeekData, oldWeekData)}
                    label={Translate[lang].thisWeek}
                    colors={Translate["theme"].circles[0]}
                />
                <Circle
                    value={Sumup(newHourlyData[showTomorrow ? 0 : 1], oldHourlyData[showTomorrow ? 0 : 1])}
                    label={Translate[lang].today}
                    colors={Translate["theme"].circles[1]}
                />
                <Circle
                    value={
                        showTomorrow
                            ? Sumup(newHourlyData[1], oldHourlyData[1])
                            : showClimate
                            ? Sumup(newHourlyData[2], oldHourlyData[2])
                            : "-"
                    }
                    label={Translate[lang].tomorrow}
                    colors={Translate["theme"].circles[2]}
                />
            </div>
        );
    };

    const Sumup = useCallback((newValue, oldValue, hour, noDecimals) => {
        let newSum = 0;
        let oldSum = 0;
        if (newValue || oldValue) {
            newValue.map((a) => (newSum = +a + newSum));
            oldValue.map((a) => (oldSum = +a + oldSum));
        }
        let sum = oldSum - newSum;
        if (hour) {
            sum = sum / hour;
        }
        if (noDecimals) {
            return sum.toFixed(0);
        }
        return sum.toFixed(2);
    }, []);

    const sum = useCallback(({ newValues, oldValues, hours, noDecimals }) => {
        const newSum = newValues.reduce((acc, curr) => acc + Number(curr), 0);
        const oldSum = oldValues.reduce((acc, curr) => acc + Number(curr), 0);
        let sum = oldSum - newSum;
        if (hours) {
            sum = sum / hours;
        }
        if (noDecimals) {
            return sum.toFixed(0);
        }
        return sum.toFixed(2);
    }, []);

    const desc = Translate[lang]?.description;

    /*const Chart3 = memo(({id, title, labels, priceData, colors, tempData, newData, oldData, newDataName, oldDataName, currency, width}) =>                 
        <Chart
            id={id}
            title={title}
            labels={labels}
            priceData={priceData}
            colors={colors}
            tempData={tempData}
            newData={newData}
            oldData={oldData}
            newDataName={newDataName}
            oldDataName={oldDataName}
            currency={currency}
            width={width}
        />
    )*/

    return (
        <>
            <div
                ref={ref}
                className={`bgImage flex h-full ` + (newCostHourly === null && monetaryEnabled ? `hidden` : ``)}
            >
                <div
                    className={`bg-nightblue text-base transition-all duration-700 overflow-hidden ease-in-out print:hidden absolute h-full  md:h-auto z-50 md:static ${
                        hideMenu ? " w-full md:w-base xl:w-large" : "w-0"
                    } `}
                >
                    <div className={`p-6 lg:p-10 ${hideMenu ? " " : "hidden"}`}>
                        <button
                            type="button"
                            className="absolute top-2 right-1 block md:hidden"
                            onClick={() => setHideMenu(false)}
                        >
                            <XCircleIcon className="w-10 h-10" />
                        </button>
                        {showInfo ? (
                            <div className="mt-5">
                                <p className="mb-5 text-base sm:text-lg mr-3 md:mr-0">{Translate[lang].infoBody}</p>

                                <p className="text-base sm:text-lg">
                                    {Translate[lang].infoFormula}
                                    <a
                                        className="underline"
                                        href="https://www.heatspaceandlight.com/calculate-much-energy-windows-will-save-u-values/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {Translate[lang].infoHere}
                                    </a>
                                </p>
                                <h2 className="mb-5">{Translate[lang].dataSourcesTitle}</h2>
                                <h3 className="h3-data">
                                    {Translate[lang].dataKwhPrice}
                                    <a
                                        className="a-data"
                                        href="https://www.entsoe.eu/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Entsoe
                                    </a>
                                </h3>
                                <h3 className="h3-data">
                                    {Translate[lang].dataWeather}
                                    <a className="a-data" href="https://www.met.no/" target="_blank" rel="noreferrer">
                                        Met.no
                                    </a>
                                </h3>
                                <h3 className="h3-data">
                                    {Translate[lang].dataExchangeRates}
                                    <a
                                        className="a-data"
                                        href="https://www.norges-bank.no/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Norges Bank
                                    </a>
                                </h3>
                                <h3 className="h3-data">
                                    {Translate[lang].dataEmission}
                                    <a
                                        className="a-data"
                                        href="https://www.nowtricity.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Nowtricity
                                    </a>
                                </h3>
                            </div>
                        ) : (
                            <div>
                                <div className="setItem">
                                    <label className="block" htmlFor="biddingZone">
                                        {Translate[lang].region + options.biddingZone.index}
                                    </label>
                                    <DropDown
                                        id="biddingZone"
                                        value={options.biddingZone.index}
                                        setValue={(e) => setBiddingZone(BiddingZone.byIndex(e.target.value))}
                                        loopValue={BiddingZone.zones}
                                    />
                                </div>

                                <div className="setItem">
                                    <BetterInput
                                        id="fixedWindowsArea"
                                        type="number"
                                        placeholder={Translate[lang].enterFixedWindowArea}
                                        onChange={(e) => setFixedWindowsArea(e)}
                                        value={fixedWindowsArea === -1 ? "" : fixedWindowsArea}
                                        label={Translate[lang].fixedWindowArea + " %s m<sup>2</sup>"}
                                    />
                                </div>
                                <div className="setItem">
                                    <BetterInput2
                                        id="fixedPrice"
                                        type="text"
                                        placeholder={Translate[lang].enterFixedPrice}
                                        setValue={(e) => setFixedPrice(e)}
                                        value={fixedPrice}
                                        label={Translate[lang].fixedPrice + " %s " + options.biddingZone.currency}
                                        visibleValue={visibleFixedPrice}
                                        setVisibleValue={setVisibleFixedPrice}
                                    />
                                </div>
                                <div className="setItem">
                                    <BetterInput
                                        id="fixedTemp"
                                        type="number"
                                        placeholder={Translate[lang].enterFixedTemp}
                                        onChange={(e) => setFixedTemp(e)}
                                        value={fixedTemp === -1 ? "" : fixedTemp}
                                        label={Translate[lang].fixedTemp + "\0"}
                                    />
                                </div>
                                {fixedTemp === "" && (
                                    <div className="setItem">
                                        <BetterInput
                                            id="outTempOffset"
                                            type="range"
                                            min="-20"
                                            max="20"
                                            onChange={(e) => {
                                                setOutTempOffset(e);
                                            }}
                                            value={outTempOffset}
                                            className="range"
                                            label={Translate[lang].OutdoorTempOffset + "%s°C"}
                                        />
                                    </div>
                                )}

                                {showElstod && (
                                    <div className="setItem">
                                        <label className="block" htmlFor="elstod">
                                            {Translate[lang].elstod} {elstod}%
                                        </label>
                                        <DropDown
                                            id="elstod"
                                            value={elstod}
                                            setValue={(e) => setElstod(e.target.value)}
                                            loopValue={[{ index: 0 }, { index: 80 }, { index: 90 }]}
                                            text="%"
                                        />
                                    </div>
                                )}

                                <div className="setItem">
                                    <BetterInput
                                        id="inTemp"
                                        type="range"
                                        step={1}
                                        min="0"
                                        max="40"
                                        onChange={(e) => setIndoorTemp(e)}
                                        value={indoorTemp}
                                        className="range"
                                        label={Translate[lang].IndoorTemp + "%s°C"}
                                    />
                                </div>
                                <div className="setItem">
                                    <BetterInput
                                        id="oldU"
                                        step="0.01"
                                        type="range"
                                        placeholder="old u-value"
                                        onChange={(e) => setOldU(parseFloat(e))}
                                        value={oldU}
                                        min="0.8"
                                        max="3.0"
                                        className="range"
                                        label={Translate[lang].oldUValue}
                                    />
                                </div>
                                <div className="setItem">
                                    <BetterInput
                                        id="newU"
                                        step="0.01"
                                        type="range"
                                        placeholder="new u-value"
                                        onChange={(e) => setNewU(parseFloat(e))}
                                        value={newU}
                                        min="0.4"
                                        max="1.6"
                                        className="range"
                                        label={Translate[lang].newUValue}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-full">
                    <div className="fixed block">
                        <button
                            onClick={() => {
                                setShowInfo(false);
                                if (hideMenu && showInfo) return;
                                setHideMenu(!hideMenu);
                            }}
                            className="bg-nightblue p-2 lg:p-4 block"
                        >
                            <AdjustmentsHorizontalIcon className="h-8 w-8  " />
                        </button>
                        <button
                            onClick={() => {
                                setShowInfo(true);
                                if (hideMenu && !showInfo) return;
                                setHideMenu(!hideMenu);
                            }}
                            className="bg-nightblue p-2 lg:p-4 block"
                        >
                            <InformationCircleIcon className="h-8 w-8  " />
                        </button>
                    </div>
                    <div className="max-w-screen-xl mx-auto mt-20 p-4 md:p-10 ">
                        <h1>{Translate[lang].energySavings}</h1>
                        <p className="text-lg xl:text-2xl mb-5">
                            {desc?.regionD}
                            <span onClick={() => setHideMenu(true)} className="dynamicField cursor-pointer">
                                {options.biddingZone.index}
                            </span>
                            {". " + desc?.indoorD}
                            <span onClick={() => setHideMenu(true)} className="dynamicField cursor-pointer">
                                {indoorTemp}°C
                            </span>
                            {". " + desc?.windowsFromD}
                            <span onClick={() => setHideMenu(true)} className="dynamicField cursor-pointer">
                                {options.buildYear}
                            </span>
                            {" " + desc?.oldUValueD + " "}
                            <span onClick={() => setHideMenu(true)} className="dynamicField cursor-pointer">
                                {oldU}
                            </span>
                            {". " + desc?.newUValueD + " "}
                            <span onClick={() => setHideMenu(true)} className="dynamicField cursor-pointer">
                                {newU}
                            </span>
                            {". " + desc?.windowsD + " "}
                            {fixedWindowsArea <= 0 ? (
                                <>
                                    <span className="dynamicField">{options.windows}</span>
                                    {" " + desc?.dimensionD + " "}
                                </>
                            ) : (
                                <>
                                    <span className="dynamicField">
                                        {fixedWindowsArea}m<sup>2</sup>
                                    </span>{" "}
                                    {desc?.windowAreaD}.
                                </>
                            )}
                        </p>
                        <div className="md:flex mb-20">
                            <p className="text-base mr-2 font-bold ">
                                <button
                                    onClick={() => {
                                        setShowInfo(false);
                                        if (hideMenu && showInfo) {
                                            // hideMenu är nog flippad (är true när den borde vara false, o well)
                                            return;
                                        }
                                        setHideMenu(!hideMenu);
                                    }}
                                    className="flex items-center uppercase bg-nightblue px-6 py-4 rounded-lg shadow-md shadow-nightblue mb-2 w-full md:w-auto"
                                >
                                    <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2 " />
                                    {Translate[lang].changeValues}
                                </button>
                            </p>
                            <p className="text-base mr-2 font-bold ">
                                <button
                                    onClick={() => {
                                        setShowInfo(true);
                                        if (hideMenu && !showInfo) {
                                            return;
                                        }
                                        setHideMenu(!hideMenu);
                                    }}
                                    className="flex items-center uppercase bg-nightblue px-6 py-4 rounded-lg shadow-md shadow-nightblue mb-2 w-full md:w-auto"
                                >
                                    <InformationCircleIcon className="h-5 w-5 mr-2 " />
                                    {Translate[lang].infoButton}
                                </button>
                            </p>
                            <p className="text-base font-bold">
                                <button
                                    onClick={() => {
                                        screenshot(
                                            ref.current,
                                            options.biddingZone.index + " " + new Date().toISOString().slice(0, 10)
                                        );
                                    }}
                                    className="flex items-center uppercase bg-nightblue px-6 py-4 rounded-lg shadow-md shadow-nightblue mb-2 w-full md:w-auto"
                                >
                                    <PhotoIcon className="h-5 w-5 mr-2"></PhotoIcon>
                                    Save
                                </button>
                            </p>
                        </div>
                        <div className="flex">
                            <p className={"text-base mb-2 mr-10 font-bold " + (monetaryEnabled ? "" : "opacity-50")}>
                                <button
                                    onClick={() => setShowClimate(false)}
                                    className={`py-4 uppercase text-lg ${showClimate ? "" : "border-b-4  "} `}
                                >
                                    {monetaryEnabled
                                        ? Translate[lang].monetarySavings
                                        : options.biddingZone.index + " currently has API issues."}
                                </button>
                            </p>
                            <p className="text-base mb-2 font-bold ">
                                <button
                                    onClick={() => setShowClimate(true)}
                                    className={`py-4 uppercase text-lg ${showClimate ? "border-b-4" : "  "} `}
                                    //  className="flex items-center uppercase bg-nightblue px-4 py-2 rounded-lg shadow-md shadow-nightblue"
                                >
                                    {Translate[lang].climateSavings}
                                </button>
                            </p>
                        </div>

                        <div className="grid gap-6 md:grid-cols-2 text-center">
                            <Circles />
                            <div className="shadow-2xl py-10 rounded-3xl">
                                <h2 className="mb-0 mt-10">{Translate[lang].energySaved}</h2>
                                <p className="mb-10">{Translate[lang].savingsIn} </p>
                                <Circle
                                    value={pct.toFixed(0) + "%"}
                                    label={Translate[lang].pctSaving}
                                    colors={Translate["theme"].circles[0]}
                                />
                                {/* <Circle
                                    value={((pct * 150000) / 100).toFixed(0)}
                                    label={Translate[lang].fiveYears}
                                    colors={Translate["theme"].circles[1]}
                                    />*/}

                                <Circle
                                    value={
                                        priceData &&
                                        newCostHourly &&
                                        oldCostHourly &&
                                        newKwhHourly &&
                                        oldKwhHourly &&
                                        sum({
                                            newValues: newKwhHourly[showTomorrow ? 0 : 1],
                                            oldValues: oldKwhHourly[showTomorrow ? 0 : 1],
                                            noDecimals: true,
                                        })
                                    }
                                    label={Translate[lang].today + "(w)"}
                                    colors={Translate["theme"].circles[2]}
                                />
                                <Circle
                                    value={
                                        priceData && newCostHourly && oldCostHourly && newKwhHourly && oldKwhHourly
                                            ? showTomorrow
                                                ? sum({
                                                      newValues: newKwhHourly[1],
                                                      oldValues: oldKwhHourly[1],
                                                      noDecimals: true,
                                                  })
                                                : sum({
                                                      newValues: newKwhHourly[2],
                                                      oldValues: oldKwhHourly[2],
                                                      noDecimals: true,
                                                  })
                                            : "-"
                                    }
                                    label={Translate[lang].tomorrow + "(w)"}
                                    colors={Translate["theme"].circles[3]}
                                />
                            </div>
                        </div>
                        {showClimate || (!showClimate && monetaryEnabled) ? (
                            !showClimate || (showClimate && climateEnabled) ? (
                                <div>
                                    {/* <div className="shadow-2xl md:pt-20 p-6 md:pb-10  rounded-3xl md:px-20">
                                        {newC02Hourly && oldC02Hourly && newCostHourly && oldCostHourly && (
                                            <Table
                                                data={
                                                    showClimate
                                                        ? sum({
                                                              newValues: newC02Hourly[showTomorrow ? 0 : 1],
                                                              oldValues: oldC02Hourly[showTomorrow ? 0 : 1],
                                                          })
                                                        : sum({
                                                              newValues: newCostHourly[showTomorrow ? 0 : 1],
                                                              oldValues: oldCostHourly[showTomorrow ? 0 : 1],
                                                          })
                                                }
                                                w={sum({
                                                    newValues: newKwhHourly[showTomorrow ? 0 : 1],
                                                    oldValues: oldKwhHourly[showTomorrow ? 0 : 1],
                                                    noDecimals: true,
                                                })}
                                                showClimate={showClimate}
                                                lang={lang}
                                            />
                                        )}
                                    </div> */}

                                    <div className="shadow-2xl md:pt-20 p-6 md:pb-10  rounded-3xl md:px-20">
                                        {newC02Hourly && oldC02Hourly && newCostHourly && oldCostHourly && (
                                            <Table2
                                                windowsArea={
                                                    fixedWindowsArea > 0
                                                        ? fixedWindowsArea
                                                        : options.windows * (1.2 * 1.2)
                                                }
                                                oldU={oldU}
                                                newU={newU}
                                                avgTemp={
                                                    fixedTemp !== "" ? Number(fixedTemp) : options.biddingZone.avgTemp
                                                }
                                                indoorTemp={indoorTemp}
                                                price={fixedPrice === "" ? options.biddingZone.avgPrice : fixedPrice}
                                                c02Coeff={options.biddingZone.c02}
                                                showClimate={showClimate}
                                                lang={lang}
                                                setFixedPrice={(e) => setFixedPrice(e)}
                                                visibleValue={visibleFixedPrice}
                                                setVisibleValue={setVisibleFixedPrice}
                                                label={
                                                    Translate[lang].fixedPrice + " %s " + options.biddingZone.currency
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className={`grid gap-6 md:grid-cols-2 2xl:grid-cols-3`}>
                                        <h2 className="2xl:col-span-3 md:col-span-2">
                                            {showClimate ? Translate[lang].carbonSaved : Translate[lang].moneySaved}
                                        </h2>
                                        <div className="2xl:col-span-3">
                                            {weeklyData && tempWeek && newUWeek && oldUWeek && (
                                                <WeekChart
                                                    id={0}
                                                    title={[
                                                        showClimate
                                                            ? Translate[lang].weeklyCarbonSavings
                                                            : Translate[lang].weeklySavingsIn +
                                                              options.biddingZone.currency,
                                                        prettierDate(-7) + " - " + prettierDate(),
                                                    ]}
                                                    labels={Translate[lang].chart}
                                                    daysLang={
                                                        Translate[lang].days
                                                            ? Translate[lang].days
                                                            : Translate["en"].days
                                                    }
                                                    colors={Translate["theme"].colors}
                                                    priceData={weeklyData}
                                                    tempData={tempWeek}
                                                    newData={showClimate ? newC02Week : newUWeek}
                                                    oldData={showClimate ? oldC02Week : oldUWeek}
                                                    newDataName={
                                                        showClimate
                                                            ? "New C02"
                                                            : Translate[lang].chart === undefined
                                                            ? "New Cost"
                                                            : Translate[lang].chart.newCost
                                                    }
                                                    oldDataName={
                                                        showClimate
                                                            ? "Old C02"
                                                            : Translate[lang].chart === undefined
                                                            ? "Old Cost"
                                                            : Translate[lang].chart.oldCost
                                                    }
                                                    currency={options.biddingZone.currency}
                                                    width="100%"
                                                />
                                            )}
                                        </div>
                                        <div className="2xl:col-span-2">
                                            {priceData && newCostHourly && oldCostHourly && (
                                                <Chart
                                                    id={1}
                                                    title={[
                                                        showClimate
                                                            ? Translate[lang].dailyCarbonSavings
                                                            : Translate[lang].dailySavingsIn +
                                                              options.biddingZone.currency,
                                                        prettierDate(),
                                                    ]}
                                                    labels={Translate[lang].chart}
                                                    priceData={priceData[priceData.length - (showTomorrow ? 2 : 1)]}
                                                    colors={Translate["theme"].colors}
                                                    tempData={showTomorrow ? tempToday : tempTomorrow}
                                                    newData={
                                                        showClimate
                                                            ? newC02Hourly[showTomorrow ? 0 : 1]
                                                            : newCostHourly[showTomorrow ? 0 : 1]
                                                    }
                                                    oldData={
                                                        showClimate
                                                            ? oldC02Hourly[showTomorrow ? 0 : 1]
                                                            : oldCostHourly[showTomorrow ? 0 : 1]
                                                    }
                                                    newDataName={
                                                        showClimate
                                                            ? "New C02"
                                                            : Translate[lang].chart === undefined
                                                            ? "New Cost"
                                                            : Translate[lang].chart.newCost
                                                    }
                                                    oldDataName={
                                                        showClimate
                                                            ? "Old C02"
                                                            : Translate[lang].chart === undefined
                                                            ? "Old Cost"
                                                            : Translate[lang].chart.oldCost
                                                    }
                                                    currency={options.biddingZone.currency}
                                                    width="100%"
                                                />
                                            )}
                                        </div>
                                        <div className="chart-2">
                                            {showClimate ? (
                                                priceData &&
                                                newC02Hourly &&
                                                oldC02Hourly && (
                                                    <Chart
                                                        id={2}
                                                        title={[
                                                            showClimate
                                                                ? Translate[lang].tomorrowCarbonSavings
                                                                : Translate[lang].savingsTomorrowIn +
                                                                  options.biddingZone.currency,
                                                            prettierDate(1),
                                                        ]}
                                                        labels={Translate[lang].chart}
                                                        priceData={priceData[priceData.length - 1]}
                                                        colors={Translate["theme"].colors}
                                                        tempData={showTomorrow ? tempTomorrow : tempDayahead}
                                                        newData={newC02Hourly[showTomorrow ? 1 : 2]}
                                                        oldData={oldC02Hourly[showTomorrow ? 1 : 2]}
                                                        newDataName={
                                                            showClimate
                                                                ? "New C02"
                                                                : Translate[lang].chart === undefined
                                                                ? "New Cost"
                                                                : Translate[lang].chart.newCost
                                                        }
                                                        oldDataName={
                                                            showClimate
                                                                ? "Old C02"
                                                                : Translate[lang].chart === undefined
                                                                ? "Old Cost"
                                                                : Translate[lang].chart.oldCost
                                                        }
                                                        currency={options.biddingZone.currency}
                                                        width="100%"
                                                    />
                                                )
                                            ) : showTomorrow ? (
                                                priceData &&
                                                newCostHourly &&
                                                oldCostHourly && (
                                                    <Chart
                                                        id={2}
                                                        title={[
                                                            showClimate
                                                                ? Translate[lang].tomorrowDailySavings
                                                                : Translate[lang].savingsTomorrowIn +
                                                                  options.biddingZone.currency,
                                                            prettierDate(1),
                                                        ]}
                                                        labels={Translate[lang].chart}
                                                        priceData={priceData[priceData.length - 1]}
                                                        colors={Translate["theme"].colors}
                                                        tempData={tempTomorrow}
                                                        newData={newCostHourly[1]}
                                                        oldData={oldCostHourly[1]}
                                                        newDataName={
                                                            showClimate
                                                                ? "New C02"
                                                                : Translate[lang].chart === undefined
                                                                ? "New Cost"
                                                                : Translate[lang].chart.newCost
                                                        }
                                                        oldDataName={
                                                            showClimate
                                                                ? "Old C02"
                                                                : Translate[lang].chart === undefined
                                                                ? "Old Cost"
                                                                : Translate[lang].chart.oldCost
                                                        }
                                                        currency={options.biddingZone.currency}
                                                        width="100%"
                                                    />
                                                )
                                            ) : (
                                                <Timer start={timeUntilUpdate} lang={lang} />
                                            )}
                                        </div>
                                    </div>

                                    <div className={`grid gap-6 md:grid-cols-2 2xl:grid-cols-3`}>
                                        <h2 className="2xl:col-span-3 md:col-span-2">{Translate[lang].energySaved}</h2>
                                        <div className="2xl:row-span-2">
                                            {newCostHourly && oldCostHourly && (
                                                <Donut
                                                    newUData={newCostHourly[0]}
                                                    oldUData={oldCostHourly[0]}
                                                    colors={Translate["theme"].colors}
                                                    setPct={setPct}
                                                    title={Translate[lang].saveUpTo}
                                                />
                                            )}
                                        </div>
                                        <div className="2xl:col-span-2">
                                            {
                                                <YearKwh
                                                    title={Translate[lang].yearlyKwhUsage}
                                                    labels={Translate[lang].chart}
                                                    colors={Translate["theme"].colors}
                                                    pct={pct}
                                                    width="830"
                                                />
                                            }
                                        </div>

                                        <div className="chart-1">
                                            {priceData && newCostHourly && oldCostHourly && (
                                                <Chart
                                                    id={3}
                                                    title={[Translate[lang].hourlySavingsKwh, prettierDate()]}
                                                    labels={Translate[lang].chart}
                                                    priceData={priceData[priceData.length - 2]}
                                                    colors={Translate["theme"].colors}
                                                    tempData={showTomorrow ? tempToday : tempTomorrow}
                                                    newData={newKwhHourly[showTomorrow ? 0 : 1]}
                                                    oldData={oldKwhHourly[showTomorrow ? 0 : 1]}
                                                    newDataName={
                                                        Translate[lang].chart === undefined
                                                            ? "New kwh"
                                                            : Translate[lang].chart.new + " kwh"
                                                    }
                                                    oldDataName={
                                                        Translate[lang].chart === undefined
                                                            ? "Old kwh"
                                                            : Translate[lang].chart.old + " kwh"
                                                    }
                                                    currency={options.biddingZone.currency}
                                                    width="100%"
                                                />
                                            )}
                                        </div>
                                        <div className="chart-2">
                                            {priceData && newCostHourly && oldCostHourly && (
                                                <Chart
                                                    id={4}
                                                    title={[Translate[lang].savingsTomorrowKwh, prettierDate(1)]}
                                                    labels={Translate[lang].chart}
                                                    priceData={priceData[priceData.length - 1]}
                                                    colors={Translate["theme"].colors}
                                                    tempData={showTomorrow ? tempTomorrow : tempDayahead}
                                                    newData={newKwhHourly[showTomorrow ? 1 : 2]}
                                                    oldData={oldKwhHourly[showTomorrow ? 1 : 2]}
                                                    newDataName={
                                                        Translate[lang].chart === undefined
                                                            ? "New kwh"
                                                            : Translate[lang].chart.new + " kwh"
                                                    }
                                                    oldDataName={
                                                        Translate[lang].chart === undefined
                                                            ? "Old kwh"
                                                            : Translate[lang].chart.old + " kwh"
                                                    }
                                                    currency={options.biddingZone.currency}
                                                    width="100%"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <h1 className="text-center my-10">
                                    {options.biddingZone.index} currently experience some temporary downtime during
                                    development.
                                </h1>
                            )
                        ) : (
                            <h1 className="text-center my-10">
                                {options.biddingZone.index} currently has issues with getting data from Entsoe, usually
                                this is resolved within a day.
                            </h1>
                        )}
                        <Footer title={Translate[lang].aPartOf} />
                    </div>
                </div>
            </div>
        </>
    );
}
