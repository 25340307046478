import ReactApexChart from "react-apexcharts";

export default function YearKwh({ pct, title, labels, colors }) {
  const createTitleYears = () => {
    const currentYear = new Date().getFullYear();
    return currentYear - 5 + " - " + currentYear;
  };

  const createXAxisCategories = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 5;
    let result = [];
    for (let i = startYear; i <= currentYear; i++) {
      result.push(i);
    }
    return result;
  };

  const createNewSeriesData = (pct) => {
    const old = [25000, 50000, 75000, 100000, 125000, 150000];
    let result = [];
    if (pct === 0) {
      // undvik divide by zero D:
      return [];
    }
    let multiplier = pct / 100;
    multiplier = 1 - multiplier;
    old.forEach((e) => {
      result.push(parseFloat(e * multiplier).toFixed(0));
    });
    return result;
  };

  const longChartData = {
    series: [
      {
        name: labels ? labels.old : "-",
        data: [25000, 50000, 75000, 100000, 125000, 150000],
      },
      {
        name: labels ? labels.new : "-",
        data: createNewSeriesData(pct),
      },
    ],
    options: {
      colors: [colors[1], colors[2]],
      chart: {
        foreColor: "#777777",
        type: "area",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "straight",
        width: 2,
        dashArray: 0,
      },

      xaxis: {
        categories: createXAxisCategories(),
      },
      yaxis: [
        {
          title: {
            text: "kWH",
          },
        },
      ],
      tooltip: {
        enabled: true,
        theme: "dark",
        fillSeriesColor: true,
        x: {
          show: false,
        },
      },
    },
  };

  return (
    <div className="chart">
      <h3 className="text-center">{title}</h3>
      <h4 className="text-center ">{createTitleYears()}</h4>
      <ReactApexChart
        options={longChartData.options}
        series={longChartData.series}
        type="area"
        height={250}
        width="100%"
      />
    </div>
  );
}
