import Logo from "./logo.jsx";

const Footer = ({ title }) => {
  return (
    <footer className="py-10">
      <div className="flex justify-between">
        <a href="https://nordangruppen.no" target="_blank" rel="noreferrer">
          <div className="flex items-center gap-3">
            <Logo classes="w-5 h-5 md:w-6 md:h-6" />
            <strong className="uppercase">{title}</strong>
          </div>
        </a>

        {/*<div className="flex gap-4">
          <a
            href="https://www.youtube.com/@nordangruppen363"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              aria-label="YouTube"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 36 36"
              className="w-5 h-5 md:w-6 md:h-6"
            >
              <g id="Group_224" transform="translate(0 0.035)">
                <path
                  id="Path_2586"
                  d="M120.433,101.622,112,96v11.244Z"
                  transform="translate(-97.022 -83.498)"
                  fill="#fff"
                />
                <path
                  id="Path_2587"
                  d="M24,59.64a32.277,32.277,0,0,0,.764,7.93,2.246,2.246,0,0,0,1.36,1.538c4.725,1.816,12.555,1.766,12.555,1.766s7.83.05,12.555-1.766a2.246,2.246,0,0,0,1.36-1.538,32.276,32.276,0,0,0,.764-7.93,32.277,32.277,0,0,0-.764-7.93,2.246,2.246,0,0,0-1.36-1.538c-4.725-1.816-12.555-1.766-12.555-1.766s-7.83-.05-12.555,1.766a2.246,2.246,0,0,0-1.36,1.538A32.276,32.276,0,0,0,24,59.64Z"
                  transform="translate(-20.613 -41.516)"
                  fill="rgba(0,0,0,0)"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
              </g>
            </svg>
          </a>
          
          <a
            href="https://www.facebook.com/NorDanSverige/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              aria-label="Facebook"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 36 36"
              className="w-5 h-5 md:w-6 md:h-6"
            >
              <g id="Group_227" transform="translate(-0.133)">
                <ellipse
                  id="Ellipse_23"
                  cx="14"
                  cy="13.5"
                  rx="14"
                  ry="13.5"
                  transform="translate(4.133 5)"
                  fill="rgba(0,0,0,0)"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <path
                  id="Path_2589"
                  d="M133.645,88h-2.258A3.387,3.387,0,0,0,128,91.388V107.2"
                  transform="translate(-109.716 -75.581)"
                  fill="rgba(0,0,0,0)"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <line
                  id="Line_54"
                  x2="10"
                  transform="translate(13.133 20)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
              </g>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/nordan_sverige/"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              aria-label="Instagram"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 36 36"
              className="w-5 h-5 md:w-6 md:h-6"
            >
              <g id="Group_225" transform="translate(-0.309)">
                <circle
                  id="Ellipse_20"
                  cx="5.5"
                  cy="5.5"
                  r="5.5"
                  transform="translate(12.309 13)"
                  fill="rgba(0,0,0,0)"
                  stroke="#fff"
                  strokeMiterlimit="10"
                  strokeWidth="3"
                />
                <rect
                  id="Rectangle_152"
                  width="25"
                  height="26"
                  rx="4"
                  transform="translate(5.309 5)"
                  fill="rgba(0,0,0,0)"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <circle
                  id="Ellipse_21"
                  cx="2"
                  cy="2"
                  r="2"
                  transform="translate(23.309 9)"
                  fill="#fff"
                />
              </g>
            </svg>
  </a>
          <a
            href="https://www.linkedin.com/company/nordangruppen"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              aria-label="aedIn"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 36 36"
              className="w-5 h-5 md:w-6 md:h-6"
            >
              <g id="Group_226" transform="translate(0.011)">
                <rect
                  id="Rectangle_153"
                  width="36"
                  height="36"
                  transform="translate(-0.011)"
                  fill="rgba(0,0,0,0)"
                />
                <rect
                  id="Rectangle_154"
                  width="26"
                  height="26"
                  rx="4"
                  transform="translate(4.989 5)"
                  fill="rgba(0,0,0,0)"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <line
                  id="Line_52"
                  y2="9"
                  transform="translate(16.989 16)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <line
                  id="Line_53"
                  y2="9"
                  transform="translate(12.989 16)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <path
                  id="Path_2588"
                  d="M120,115.953a3.952,3.952,0,0,1,7.9,0v5.081"
                  transform="translate(-102.905 -96.194)"
                  fill="rgba(0,0,0,0)"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <circle
                  id="Ellipse_22"
                  cx="1.5"
                  cy="1.5"
                  r="1.5"
                  transform="translate(10.989 10)"
                  fill="#fff"
                />
              </g>
            </svg>
          </a>
        </div>*/}
      </div>
    </footer>
  );
};

export default Footer;
