import { useEffect, useState } from "react";
import Translate from "../translate/Translate.json";
import BetterInput2 from "./BetterInput2";

const Table2 = ({
    windowsArea,
    oldU,
    newU,
    avgTemp,
    indoorTemp,
    price,
    c02Coeff,
    showClimate,
    lang,
    setFixedPrice,
    visibleValue,
    setVisibleValue,
    label,
}) => {
    const [w, setW] = useState(0);
    const [cost, setCost] = useState(0);
    const [c02, setC02] = useState(0);

    useEffect(() => {
        const uArea = windowsArea * (oldU - newU);
        let tempDelta = indoorTemp - avgTemp;
        if (tempDelta < 0) tempDelta = 0;
        const wh = uArea * tempDelta;
        const value = wh * 24 * 0.8;
        setW(value);
        setC02(value * c02Coeff);
        const kwh = value / 1000;
        setCost(kwh * price);
    }, [windowsArea, oldU, newU, avgTemp, indoorTemp, price, c02Coeff]);

    const numberWithSpaces = (x) => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        return parts.join(".");
    };

    const kwh = (years) => {
        return numberWithSpaces(((w * (years * 365)) / 1000).toFixed(1));
    };

    const shekels = (years) => {
        if (showClimate) {
            return numberWithSpaces(((c02 * (years * 365)) / 1000).toFixed(1)) + " kg";
        }
        return numberWithSpaces((cost * (years * 365)).toFixed(0)) + ".-";
    };

    /* console.log("price: " + price); */

    return (
        <div>
            {price === "" || price === -1 ? (
                <div className="text-center">
                    <div className="font-black text-3xl">{Translate[lang].tableIntroHead}</div>
                    <div className="mt-8 text-lg">{Translate[lang].tableIntroBody}</div>
                    <div className="flex justify-center my-4">
                        <div className="w-1/4">
                            <BetterInput2
                                id="tablePrice"
                                type="text"
                                placeholder={Translate[lang].enterFixedPrice}
                                setValue={(e) => setFixedPrice(e)}
                                value={price}
                                visibleValue={visibleValue}
                                setVisibleValue={setVisibleValue}
                                className="bg-nightblue text-center"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <table id="tableOne" className="w-full" cellSpacing="0" cellPadding="0" border="0">
                        <colgroup>
                            <col width="auto" />
                            <col width="15%" />
                            <col width="15%" />
                        </colgroup>
                        <thead>
                            <tr className="">
                                <th className="text-left p-2">{Translate[lang].year}</th>
                                <th className="text-right p-2">kWh</th>

                                <th className="text-right p-2">{showClimate ? "C02" : Translate[lang].savingsIn}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-dawnblue">
                                <td className="energikalk-col1  " id="YearCol">
                                    1
                                </td>
                                <td className="energikalk-col2  " id="ResKwhy1">
                                    {kwh(1)}
                                </td>

                                <td className="energikalk-col2  " id="ResBespY1">
                                    {shekels(1)}
                                </td>
                            </tr>
                            <tr className="">
                                <td className="energikalk-col1  " id="YearCol">
                                    3
                                </td>
                                <td className="energikalk-col2  " id="ResKwhy3">
                                    {kwh(3)}
                                </td>

                                <td className="energikalk-col2   " id="ResBespY3">
                                    {shekels(3)}
                                </td>
                            </tr>
                            <tr className="bg-dawnblue">
                                <td className="energikalk-col1  " id="YearCol">
                                    5
                                </td>
                                <td className="energikalk-col2  " id="ResKwhy5">
                                    {kwh(5)}
                                </td>

                                <td className="energikalk-col2   " id="ResBespY5">
                                    {shekels(5)}
                                </td>
                            </tr>
                            <tr className="">
                                <td className="energikalk-col1  " id="YearCol">
                                    10
                                </td>
                                <td className="energikalk-col2  " id="ResKwhy10">
                                    {kwh(10)}
                                </td>

                                <td className="energikalk-col2   " id="ResBespY10">
                                    {shekels(10)}
                                </td>
                            </tr>
                            <tr className="bg-dawnblue">
                                <td className="energikalk-col1  " id="YearCol">
                                    20
                                </td>
                                <td className="energikalk-col2  " id="ResKwhy20">
                                    {kwh(20)}
                                </td>

                                <td className="energikalk-col2   " id="ResBespY20">
                                    {shekels(20)}
                                </td>
                            </tr>
                            <tr>
                                <td className="energikalk-col1  " id="YearCol">
                                    30
                                </td>
                                <td className="energikalk-col2  " id="ResKwhy30">
                                    {kwh(30)}
                                </td>

                                <td className="energikalk-col2  " id="ResBespY30">
                                    {shekels(30)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="flex flex-col justify-center items-center my-4">
                        <div className="w-1/4">
                            <BetterInput2
                                id="tablePrice"
                                type="text"
                                placeholder={Translate[lang].enterFixedPrice}
                                setValue={(e) => setFixedPrice(e)}
                                value={price}
                                visibleValue={visibleValue}
                                setVisibleValue={setVisibleValue}
                                className="bg-nightblue text-center"
                                label={label}
                                labelClass="text-center"
                            />
                        </div>
                        <h4>{Translate[lang].tableIntroBody}</h4>
                        <h4>{Translate[lang].tableInfo}</h4>
                    </div>
                </>
            )}
        </div>
    );
};

export default Table2;
