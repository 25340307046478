import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";

export default function Donut({ newUData, oldUData, setPct, title, colors }) {
  const [avgNewU, setAvgNewU] = useState(1);
  const [avgOldU, setAvgOldU] = useState(2);

  const donutData = {
    series: [avgNewU, avgOldU],
    options: {
      colors: [colors[2],colors[1]],
      chart: {
        foreColor: "#777777",
      },
      stroke: {
        show: false,
      },
      labels: ["New U", "Old U"],
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            // labels: {
            //   show: true,
            //   total: {
            //     show: true,
            //     showAlways: true,
            //     label: "Save up to",
            //     formatter: function (w) {
            //       let newU = w.config.series[0];
            //       let oldU = w.config.series[1];
            //       if (newU === 0 || oldU === 0) {
            //         // divide by zero monka
            //         setPct(0);
            //         return "0.00%";
            //       }
            //       const pct = (1 - newU / oldU) * 100;
            //       setPct(pct);
            //       return parseFloat(pct).toFixed(2) + "%";
            //     },
            //   },
            // },
          },
        },
      },
    },
  };

  useEffect(() => {
    const avg = (data) => {
      let result = 0.0;
      data.forEach((element) => {
        result += parseFloat(element);
      });
      result /= data.length;
      return parseFloat(result).toFixed(2);
    };
    if (newUData !== null && oldUData !== null) {
      let newAvg = parseFloat(avg(newUData));
      let oldAvg = parseFloat(avg(oldUData));
      setAvgNewU(newAvg);
      setAvgOldU(oldAvg);
      let pct = 0;
      if (newAvg !== 0 || oldAvg !== 0) {
        pct = (1 - (newAvg / oldAvg)) * 100;
      }
      setPct(pct);
    } else {
      setAvgNewU(parseFloat(1));
      setAvgOldU(parseFloat(2));
    }
  }, [newUData, oldUData, setPct]);

  return (
    <div className="chart flex justify-center items-center">
      <div>
        <ReactApexChart
          options={donutData.options}
          series={donutData.series}
          type="donut"
          width="100%"
        />
        <h1 className="text-center mt-6 mb-0">{title}</h1>
        <h1 className="text-center ">
          {((1 - parseFloat(newUData) / parseFloat(oldUData)) * 100).toFixed(2)}
          %
        </h1>
      </div>
    </div>
  );
}
