const DropDown = ({ value, setValue, loopValue, text, id }) => (
    <div className="select">
  <select className="" value={value} onChange={setValue} id={id}>
    {loopValue.map((zone) => (
      <option key={zone.index} value={zone.index}>
        {zone.index}
        {text}
      </option>
    ))}
  </select></div>
);

export default DropDown;
