export default class BiddingZone {
    static SE1 = new BiddingZone("SE1", "10Y1001A1001A44P", "SEK", 0.019, 0.064, 0.44);
    static SE2 = new BiddingZone("SE2", "10Y1001A1001A45N", "SEK", 0.019, 1.735, 0.44);
    static SE3 = new BiddingZone("SE3", "10Y1001A1001A46L", "SEK", 0.019, 2.136, 0.55);
    static SE4 = new BiddingZone("SE4", "10Y1001A1001A47J", "SEK", 0.019, 8.036, 0.69);
    static DK1 = new BiddingZone("DK1", "10YDK-1--------W", "DKK", 0.238, 8.915, 0.65);
    static DK2 = new BiddingZone("DK2", "10YDK-2--------M", "DKK", 0.238, 9.155, 0.6);
    static NO1 = new BiddingZone("NO1", "10YNO-1--------2", "NOK", 0.033, 6.386, 0.89);
    static NO2 = new BiddingZone("NO2", "10YNO-2--------T", "NOK", 0.033, 7.154, 1.06);
    static NO3 = new BiddingZone("NO3", "10YNO-3--------J", "NOK", 0.033, 6.661, 0.56);
    static NO4 = new BiddingZone("NO4", "10YNO-4--------9", "NOK", 0.033, -4.356, 0.45);
    static NO5 = new BiddingZone("NO5", "10Y1001A1001A48H", "NOK", 0.033, 7.189, 0.89);
    static PL = new BiddingZone("PL", "10YPL-AREA-----S", "PLN", 0.704, 8.913, 0.48);
    static UK = new BiddingZone("UK", "10Y1001A1001A59C", "GBP", 0.262, 6.626, 0.25);
    static IR = new BiddingZone("IR", "10Y1001A1001A59C", "EUR", 0.391, 6.626, 0.26);
    static LT = new BiddingZone("LT", "10YLT-1001A0008Q", "EUR", 0.142, 5.25, 0.094);

    static zones = [
        this.SE1,
        this.SE2,
        this.SE3,
        this.SE4,
        this.DK1,
        this.DK2,
        this.NO1,
        this.NO2,
        this.NO3,
        this.NO4,
        this.NO5,
        this.PL,
        this.UK,
        this.IR,
    ]; // , this.LT]; // dom får kanske vara med när de har mer data!

    constructor(index, domain, currency, c02, avgTemp, avgPrice) {
        this.index = index;
        this.domain = domain;
        this.currency = currency;
        this.c02 = c02;
        this.avgTemp = avgTemp;
        this.avgPrice = avgPrice;
        Object.freeze(this);
    }

    static byIndex(index) {
        for (const zone of this.zones) {
            if (zone.index === index) {
                return zone;
            }
        }
        return null;
    }
}
