import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import React, { useEffect, useCallback } from "react";

export default function Chart({
    id,
    priceData,
    tempData,
    newData,
    oldData,
    newDataName,
    oldDataName,
    title,
    currency,
    width,
    labels,
    colors,
}) {

    /* console.log("chart re-render (" + id + ")"); */

    let chartData = {
        series: [
            {
                name: currency + " / kWH",
                data: priceData,
            },
            {
                name: oldDataName,
                data: oldData
            },
            {
                name: newDataName,
                data: newData
            },
            {
                name: "Celsius",
                data: tempData,
            },
        ],

        options: {
            colors: colors,

            chart: {
                foreColor: "#777777",
                type: "area",
                stacked: false,
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
                id: "smolChart" + id,
            },

            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                curve: "smooth",
                width: 2,
                dashArray: 0,
            },

            // grid: {
            //     enabled: false,
            //     row: {
            //         colors: ['#f3f3f3', 'transparent'],
            //         opacity: 0
            //     },
            // },
            xaxis: {
                categories: [
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                ],
            },
            yaxis: [
                {
                    title: {
                        text: currency + " / kWH",
                    },
                    show: false,
                    tickAmount: 6,
                },
                {
                    title: {
                        text: labels ? labels.old + " kwh" : "kwh"
                    },
                    show: false,
                    opposite: false
                },
                {
                    title: {
                        text: labels ? labels.new + " kwh" : "kwh"
                    },
                    show: false,
                    opposite: true
                },
                {
                    title: {
                        text: "Celsius",
                    },
                    opposite: false,
                    show: false,
                },
            ],

            tooltip: {
                enabled: true,
                theme: "dark",
                fillSeriesColor: true,
                x: {
                    show: false,
                },
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        if (value !== undefined) {
                            if (w.globals.seriesNames[seriesIndex].includes("kwh")) {
                                return (value / 1000).toFixed(3);
                            }
                            if (w.globals.seriesNames[seriesIndex] === "Celsius") {
                                return value.toFixed(2) + "°";
                            }
                            return value.toFixed(2);
                        }
                        return value;
                    },
                },
            },
        },
    };

    const calcRange = useCallback((alg, data, shouldMarginal = true, marginal = 1.2) => {
        let sum = [...data[0]];
        if (data.length > 1) {
            for (let i = 1; i < data.length; i++) {
                sum.push(...data[i]);
            }
        }
        let result = alg.apply(Math, sum);
        if (shouldMarginal) {
            return Math.round(result * marginal * 100) / 100;
        }
        return Math.round(result);
    }, []);

    const updateRanges = useCallback((data) => {
        if (chartData.options.yaxis.length === 0 || id === 0) {
            return;
        }
        let max = calcRange(Math.max, data);
        let celsiusIndex = chartData.series.findIndex((e) => e.name === "Celsius");
        let minCelsius = calcRange(Math.min, [chartData.series[celsiusIndex].data], false);
        let maxCelsius = calcRange(Math.max, [chartData.series[celsiusIndex].data], false);
        let diffCelsius = maxCelsius - minCelsius;

        let multiplier = 0.45;
        if (Math.abs(maxCelsius) < Math.abs(minCelsius)) {
            multiplier = 0.3;
        }

        let maxTmp = Math.abs(maxCelsius / multiplier);
        maxCelsius = diffCelsius / 0.25;
        if (maxTmp > maxCelsius) {
            maxCelsius = maxTmp;
        }

        chartData.options.yaxis.forEach((element) => {
            if (element.title.text.includes("/")) {
                return;
            }
            element.min = 0;
            element.max = max;
            if (element.title.text === "Celsius") {
                element.min = minCelsius;
                element.max = maxCelsius;
            }
        });
        ApexCharts.exec(
            "smolChart" + id,
            "updateOptions",
            {
                yaxis: chartData.options.yaxis,
            },
            false,
            false
        );
    }, [chartData.series, chartData.options.yaxis, id, calcRange]);

    useEffect(() => {
        if (newData !== undefined && oldData !== undefined) {
            updateRanges([newData, oldData]);
        }
    }, [newData, oldData, id, updateRanges]);

    /*useEffect(() => {
        function roundedToFixed(input, digits) {
            var rounder = Math.pow(10, digits);
            return (Math.round(input * rounder) / rounder).toFixed(digits);
        }
        const fixDecimals = (data) => {
            let result = [];
            data.forEach((e) => {
                result.push(roundedToFixed(e, 2));
            });
            return result;
        };
      //  setFixedPriceData(fixDecimals(priceData));
      //  setFixedTempData(fixDecimals(tempData));
    }, [priceData, tempData]);*/

    useEffect(() => {
        // onMount typ
        // ApexCharts.exec("smolChart" + id, "hideSeries", "Celsius");
        ApexCharts.exec("smolChart" + id, "hideSeries", currency + " / kWH");
       // console.log("chart mount (" + id + ")");
    }, [id, currency]);

    return (
        <div className="chart">
            <h3 className="text-center">{title[0]}</h3>

                <h4 className="text-center">{title[1]}</h4>

            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height={300}
                width={width}
            />
        </div>
    );
}
