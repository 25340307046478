import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import React, { useEffect, useState, useCallback } from "react";

export default function WeekChart({
    id,
    priceData,
    tempData,
    oldData,
    newData,
    oldDataName,
    newDataName,
    currency,
    title,
    width,
    daysLang,
    labels,
    colors,
}) {
    const [fixedPriceData, setFixedPriceData] = useState([]);
    const [fixedTempData, setFixedTempData] = useState([]);

    //välfärdsgudar giv mig styrka
    const getWeekdays = () => {
        const days = daysLang;
        let date = new Date();
        date.setDate(date.getDate() + 1);
        let result = [];
        for (let i = 0; i < 8; i++) {
            result[8 - (1 + i)] = days[date.getDay()];
            date.setDate(date.getDate() - 1);
        }
        return result;
    };

    let chartData = {
        series: [
            {
                name: currency + " / kWH",
                data: fixedPriceData,
            },
            {
                name: oldDataName,
                data: oldData,
            },
            {
                name: newDataName,
                data: newData,
            },
            {
                name: "Celsius",
                data: fixedTempData,
            },
        ],
        options: {
            colors: colors,
            chart: {
                foreColor: "#777777",
                type: "area",
                stacked: false,
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
                id: "weekChart" + id,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                curve: "smooth",
                width: 2,
                dashArray: 0,
            },
            xaxis: {
                categories: getWeekdays(),
            },
            yaxis: [
                {
                    title: {
                        text: currency + " / kWH",
                    },
                    show: false,
                    tickAmount: 6,
                },
                {
                    title: {
                        text: labels ? labels.oldCost : "-",
                    },
                    show: false,
                },
                {
                    title: {
                        text: labels ? labels.newCost : "-",
                    },
                    show: false,
                },
                {
                    title: {
                        text: "Celsius",
                    },
                    opposite: false,
                    show: false,
                },
            ],
            tooltip: {
                enabled: true,
                theme: "dark",
                fillSeriesColor: true,
                x: {
                    show: false,
                },
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        if (value !== undefined) {
                            if (w.globals.seriesNames[seriesIndex] === "Celsius") {
                                return value.toFixed(2) + "°";
                            }
                            return value.toFixed(2);
                        }
                        return value;
                    },
                },
            },
        },
    };

    const calcRange = useCallback((alg, data, shouldMarginal = true, marginal = 1.2) => {
        let sum = [...data[0]];
        if (data.length > 1) {
            for (let i = 1; i < data.length; i++) {
                sum.push(...data[i]);
            }
        }
        let result = alg.apply(Math, sum);
        if (shouldMarginal) {
            return Math.round(result * marginal * 100) / 100;
        }
        return Math.round(result);
    }, []);

    const updateRanges = useCallback(
        (data) => {
            if (chartData.options.yaxis.length === 0) {
                return;
            }
            let max = calcRange(Math.max, data);
            let celsiusIndex = chartData.series.findIndex((e) => e.name === "Celsius");
            let minCelsius = calcRange(Math.min, [chartData.series[celsiusIndex].data], false);
            let maxCelsius = calcRange(Math.max, [chartData.series[celsiusIndex].data], false);
            let diffCelsius = maxCelsius - minCelsius;

            let multiplier = 0.45;
            if (Math.abs(maxCelsius) < Math.abs(minCelsius)) {
                multiplier = 0.3;
            }

            let maxTmp = Math.abs(maxCelsius / multiplier);
            maxCelsius = diffCelsius / 0.25;
            if (maxTmp > maxCelsius) {
                maxCelsius = maxTmp;
            }

            chartData.options.yaxis.forEach((element) => {
                if (element.title.text.includes("kWH")) {
                    return;
                }
                element.min = 0;
                element.max = max;
                if (element.title.text === "Celsius") {
                    element.min = minCelsius;
                    element.max = maxCelsius;
                }
            });
            ApexCharts.exec(
                "weekChart" + id,
                "updateOptions",
                {
                    yaxis: chartData.options.yaxis,
                },
                false,
                false
            );
        },
        [chartData.series, chartData.options.yaxis, id, calcRange]
    );

    useEffect(() => {
        if (oldData !== undefined && newData !== undefined) {
            updateRanges([newData, oldData]);
        }
    }, [newData, oldData, id, updateRanges]);

    useEffect(() => {
        function roundedToFixed(input, digits) {
            var rounder = Math.pow(10, digits);
            return (Math.round(input * rounder) / rounder).toFixed(digits);
        }
        const fixDecimals = (data) => {
            let result = [];
            data.forEach((e) => {
                result.push(roundedToFixed(e, 2));
            });
            return result;
        };
        setFixedPriceData(fixDecimals(priceData));
        setFixedTempData(fixDecimals(tempData));
    }, [priceData, tempData]);

    useEffect(() => {
        ApexCharts.exec("weekChart" + id, "hideSeries", currency + " / kWH");
    }, [id, currency]);

    return (
        <div className="chart">
            <h3 className="text-center">{title[0]}</h3>
            <h4 className="text-center">{title[1]}</h4>
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="area"
                height={300}
                width={width}
            />
        </div>
    );
}
