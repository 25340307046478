import React, { useState, useEffect, useCallback } from "react";
import { ClockIcon } from "@heroicons/react/24/outline";

import Translate from "../translate/Translate.json"

const Timer = ({ start, lang }) => {
    const [startTime, setStartTime] = useState(-1);
    const [currentTime, setCurrentTime] = useState(-1);
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        if (start !== startTime) {
            setStartTime(start);
            setCurrentTime(start);
        }
        const interval = setInterval(() => {
            setCurrentTime(currentTime - 1);
            if (currentTime <= 0) {
                setFinished(true);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [start, startTime, currentTime])

    const prettyTime = useCallback(() => {
        const totalMs = currentTime * 1000;
        return new Date(totalMs).toISOString().slice(11, 19);
    }, [currentTime]);

    return (
        <div className="chart">
            {finished ? 
                <div>
                    <h1 className="text-center">
                        {Translate[lang].timerFinished}
                    </h1>
                </div>
            :
                <div >
                    <h3 className="text-center">
                        {Translate[lang].timerTitle}
                    </h3>
                    <h4 className="text-center mb-10">
                        {Translate[lang].timerBody}
                    </h4>
                    <h1 className="text-center ">
                        <ClockIcon className="h-32 w-32 mx-auto"/>
                        {prettyTime()}
                    </h1>
                    
                </div>
            }
        </div>
    );
}

export default Timer;